import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import CarLoan from './pages/CarLoan';
import SamomxmarebloSesxi from './pages/SamomxmarebloSesxi';
import AvtoLombardi from './pages/AvtoLombardi';
import Privacy from './pages/Privacy';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <>
    <Helmet>
    {/* Google Tag (gtag.js) */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-H903Z618D7"></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-H903Z618D7');
      `}
    </script>
  </Helmet>
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/avto-sesxi' element={<CarLoan />} />
          <Route path='/samomxmareblo-sesxi' element={<SamomxmarebloSesxi />} />
          <Route path='/avtolombardi' element={<AvtoLombardi />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/konfidencialuroba' element={<Privacy />} />
        </Routes>
        <Footer />
      </Router>
    </div>
    </>
  );
}

export default App;
