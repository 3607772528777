import React from 'react'
import '../styles/Footer.css'
import { Link } from 'react-router-dom'
import Logo from "../assets/loan-website-logo.webp"

function Footer() {
  return (
    <div className='footer'>
        <div className='leftSide'>
            <img src={Logo} alt='logo' width='70px' height='70px' />
        </div>
        <div className="rightSide">
            <div>
                <Link to="/konfidencialuroba">კონფიდენციალურობა</Link>
                <Link to="/contact">კონტაქტი</Link>
            </div>
        </div>
    </div>
  )
}

export default Footer
