import React from "react";
import { useEffect } from "react";
import ContactBox from "../components/ContactBox";
import Logo from "../assets/loan-website-logo.webp";
import "../styles/Contact.css";
import { Helmet } from "react-helmet";

function Contact() {
  useEffect(() => {
    // Add the ID to the body element when the component mounts
    document.body.id = "contact-page";

    // Clean up the ID when the component unmounts
    return () => {
      document.body.id = "";
    };
  }, []);

  return (
    <div className="contact">
      <Helmet>
        <meta charSet="utf-8" />
        <title>კონტაქტი | Gamomiwere.ge</title>
        <meta property="og:title" content="კონტაქტი | Gamomiwere.ge" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="contactOptions">
        <ContactBox
          title={"ელ-ფოსტა"}
          content={"contact@gamomiwere.ge"}
          icon={Logo}
        />
      </div>
    </div>
  );
}

export default Contact;
