import React from 'react'
import "../styles/ContactBox.css"

function ContactBox({title, icon, content}) {
  return (
    <div className='contactBox'>
      <h1 className='contactBoxTitle'>{title}</h1>
      <div className='contactBoxContentParent'>
        <img src={icon} className='contactBoxIcon' />
        <span className='contactBoxContent'>{content}</span>
      </div>
    </div>
  )
}

export default ContactBox
