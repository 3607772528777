import React from "react";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";
import Logo from "../assets/loan-website-logo.webp";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
function Navbar() {
  const [openLinks, setOpenLinks] = useState(false);

  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };

  return (
    <>
    <div className="navbar">
      <div className="leftSide">
        <img src={Logo} alt="logo" />
      </div>
      <div className="rightSide">
        <div>
          <Link to="/">მთავარი</Link>
          <Link to="/avto-sesxi">ავტო სესხი</Link>
          <Link to="/samomxmareblo-sesxi">სამომხმარებლო სესხი</Link>
          <Link to="/avtolombardi">ავტოლომბარდი</Link>
          <Link to="/contact">კონტაქტი</Link>
        </div>
        <button onClick={toggleNavbar}>
          <MenuIcon />
        </button>
      </div>
    </div>
    <div className= {`hiddenLinks ${openLinks ? 'show' : ''}`}>
          <Link to="/">მთავარი</Link>
          <Link to="/avto-sesxi">ავტო სესხი</Link>
          <Link to="/samomxmareblo-sesxi">სამომხმარებლო სესხი</Link>
          <Link to="/avtolombardi">ავტოლომბარდი</Link>
          <Link to="/contact">კონტაქტი</Link>
        </div>
    </>
  );
}

export default Navbar;
