import React, { useEffect, useState } from 'react';
import SamomxmarebloLoanData from '../assets/data/samomx-loans.json';
import "../styles/ContentTable.css"

function SamomxmarebloTable() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Promise.resolve(SamomxmarebloLoanData);
        setData(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="app">
      {data.map((item, index) => (
        <div key={index} className="table-container">
          <h2>{item.title}</h2>
          <table>
            {/* <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead> */}
            <tbody>
              {Object.entries(item.content).map(([key, value], idx) => (
                <tr key={idx}>
                  <td>{key}</td>
                  <td>
                    {typeof value === 'object' ? (
                      <pre>{JSON.stringify(value, null, 2)}</pre>
                    ) : (
                      value
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <a href={item.LoanLink} target='_blank' className='takeLoanA'><button className='takeLoanBtn'>სესხის აღება</button></a>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SamomxmarebloTable;
